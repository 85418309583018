.Scanner-Container {
    display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100vw;
      height: 100vh;
      background-color: var(--primary);
      box-sizing: border-box;
        position: fixed;
        top: 0;
        left: 0;
        padding: 20px;
        z-index: 999;
}
.Scanner-Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Scanner-Header_heading {
    font-size: 1.1rem;
    font-weight: 600;
}