.Table_top {
    background-color: gainsboro;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-radius: 10px 10px 0 0;
    margin-bottom: 4px;
}
.Table_problem {
    flex: 0.5;
}

.Table_date, .Table_status {
    flex: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Table_items {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.Table_items :last-child {
    border-radius: 0 0 10px 10px;
}

