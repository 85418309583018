.ActiveTicketItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 5px 10px;
    background-color: ghostwhite;
}

.Item_problem {
    flex: 0.5;
}
.ItemStatusContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: orange;
    padding: 5px 10px;
    border-radius: 10px !important;
}



.Item_status, .Item_date {
    flex: 0.25;
    display: flex;
    align-items: center;
    justify-content: center;
}