.ProcessingIcon {
    animation: spin 2s linear  infinite;
}

.PendingIcon {
    animation: flip 2s linear infinite;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@keyframes flip {
    from, 50% {
        transform: rotate(0);
      }
      to {
        transform: rotate(180deg);
      }
}
