.Input {
    width: 100%;
    border-radius: 10px !important;
    border: 1px var(--borderColor) solid !important;
    padding: 7px !important;
}
.Input-Select > div {
    width: 100% !important;
}
.Input-Select {
    border: 1px solid var(--borderColor) !important;
    border-radius: 10px !important;
}
.RoomShower {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 15px 0;
}

.Room {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0 !important;
    line-height: normal;
}
.RoomIcon_Container {
    background-color: var(--borderColor) !important;
    padding: 5px;
    border-radius: 100px;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.RoomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.RoomShower_right {
    display: flex;
    flex-direction: column;
}
.Skeleton-RoomShower{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-left: 20px;
}
.Skeleton-RoomAvatar > span {
    height: 50px !important;
    width: 50px !important;
}
.DeviceData {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 20px;
}
.label {
    font-size: 0.8rem;
}
.label-room {
    line-height: normal;
}