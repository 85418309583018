.ErrorContainer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    gap: 15px;
}

.Error_message {
    display: flex;
    align-items: center;
    gap: 8px;
}

.Error_retry {
    border-radius: 10px !important;
}