.Button_send {
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px !important;
    font-weight: 500 !important;
    left: 0;
    background-color: var(--companyColor) !important;
    border-color: var(--companyColor) !important;
}
.Button_send > span {
    color: white !important;
}

.Button_send:disabled {
    background-color: var(--borderColor) !important;
    border-color: var(--borderColor) !important;
}
.Button_send:disabled > span {
    color: darkgray !important;
}
.Home {
    box-sizing: border-box;
    height: 100%;
}

.Home_send {
    box-sizing: border-box;
    padding: 0 20px;
    margin-top: 25px;
}
.Link-Container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.Link-Container-Link {
    color: var(--companyColor);
}
.dot-divider {
    color: var(--companyColor);
}