.PersonaForm {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.PersonaForm_row {
    display: flex;
    flex-direction: column;
    
}

.Error_Input  > div{
    padding: 3px !important;
    gap: 3px;
}
.Error_Input {
    border: 1px solid var(--borderColor) !important;
    border-radius: 10px !important;


}
.ant-select-selection-overflow-item > span{
    border-radius: 7px !important;
    margin: 0 !important;
    background-color: var(--borderColor) !important;
    border: 1px solid var(--borderColor) !important;
}

.ant-select-selection-overflow {
    gap: 7px;
}

.required {
    font-weight: 700;
    
}