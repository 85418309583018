.TabContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.Navbar {
    display: flex;
    flex-direction: column;
    position: fixed;
    gap: 10px;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: var(--primary);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16); 
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16);
    padding-top: 10px;
    z-index: 999;
}
.Navbar_upper {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TabPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0.5;
    padding-top: 5px;
    font-size: 1rem;
    font-weight: 500;
    gap: 5px;
}

.Navigation {
    margin-top: 100px;
}
.active {
    width: 25% !important;
    background-color: var(--blue);
}
.indicator {
    height: 3px;
    width: 0px;
    transition: width .3s;
    border-radius: 10px 10px 0 0;
}

.page-container {
    box-sizing: border-box;
    height: 100%;
}
.badge {
    display: flex;
    align-items: center;
    margin-left: 5px;
    padding: 0px 5px;
    font-size: 10px;
  border-radius: 5px;
  background: var(--companyColor);
  font-weight: 600;
  color: white;
}