.QR_CTA {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.QR_CTA-Button * {
    background-color: var(--primary_tertiary) !important;
    border-color: var(--primary_secondary) !important;
    box-shadow: none !important;
}

.QR_Manuel {
    margin: 0 20px;
}
.QR_CTA-Button {
    border-radius: 10px !important;
}
