body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary) !important;
}
*{
  color: var(--primary_contrast);
  caret-color: var(--primary_contrast) !important;
}

svg {
  fill: var(--primary_contrast);
}
input {
  color: var(--primary_contrast) !important;
}
p {
  margin-bottom: 0 !important;
}
.error {
  padding: 2px 5px;
  background-color: var(--error);
  border-radius: 5px;
}

:root {
  --primary: #fff;
  --primary_secondary: #fff;
  --primary_tertiary: #f5f5f5;
  --primary_contrast: #000;
  --contrast: #707070;
  --blue: #006BEA;
  --blue_light: #D6E6FF;
  --warning: #F71735;
  --error: #FCE0D9;
  --companyColor: #006BEA;
  --borderColor: #e4e4e4;
  --progress: #FFFBEB;
  --progress_border: #FFE15C; 
  --recieved: #F3F4F7;
  --recieved_border: #6C789D;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: var(--primary) !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px !important;
  background-color: var(--primary) !important;
}
.ant-picker.ant-picker-borderless.Input {
  border: 1px solid var(--borderColor) !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  border-color: var(--companyColor) !important;
}
.ant-steps-item-finish .ant-steps-item-icon > span > span > svg {
  fill: var(--companyColor) !important;
}
.ant-steps-item-title {
  font-weight: 600;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--companyColor) !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--companyColor) !important;
}
.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--companyColor) !important;
}
.ant-select {
  width: 100% !important;
}
.ant-picker-panel {
  background-color: var(--primary) !important;
  border-color: var(--borderColor) !important;
}
.ant-select-dropdown {
  background-color: var(--primary_secondary) !important;
}


.ant-collapse-content-box  div {
  color: var(--primary_contrast) !important;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: var(--primary_secondary) !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--companyColor) !important;
}
.ant-steps-item-finish .ant-steps-item-icon * {
  color: white !important;
  fill: white !important;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--primary_tertiary) !important;
}
.ant-steps-item-wait .ant-steps-item-icon > span {
  color: var(--primary_contrast) !important;
}
.ant-dropdown-menu {
  background-color: var(--primary_secondary) !important;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: var(--primary_tertiary) !important;
}
.ant-picker-header {
  border-bottom: 1px solid var(--borderColor) !important;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid var(--borderColor) !important;
}
.ant-picker-cell .ant-picker-cell-inner:hover {
  color: var(--primary) !important;
}
.ant-picker-content > thead > tr > th {
  color: var(--primary_contrast) !important;
  font-weight: 600;
}

.IconButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  box-shadow: none !important;
  background-color: var(--borderColor) !important;
  border-radius: 5px !important;
  transition: all 0.3s;
}
.IconButton:active {
  transform: scale(0.90);
}
.ant-collapse-extra {
  display: flex;
  align-items: center;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  align-items: center !important;
  background-color: var(--borderColor) !important;
  fill: var(--primary_contrast) !important;
}
.ant-divider {
  border-top: 1px solid var(--borderColor) !important;
}

h1, h2, h3 {
  margin: 0 !important;
  color: var(--primary_contrast) !important;
}

#reader {
  margin-top: 10%;
}

.small-heading {
  font-size: 1rem;
  font-weight: 600;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}

 

@media (prefers-color-scheme: dark) {
  :root {
    --primary: #15141a;
    --primary_contrast: #fff;
    --borderColor: #2b2a33;
    --primary_secondary: #2b2a33;
    --primary_tertiary: #23222b;
    --progress: #3D3100;
    --recieved: #181C25;
    --error: #4B1406;
    --contrast: #999999;
  }
}
